
import { defineComponent } from "vue";

export default defineComponent({
  name: "ModalCard",
  components: {},
  props: {
    title: {type: String, default: ""},
    description: {type: String, default: ""},
    buttonText: {type: String, default: ""},
    image: {type: String, default: ""},
    modalId: {type: String, default: ""},
  },
});
